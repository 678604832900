.image-container {
  position: relative;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.35
  ); /* Adjust opacity (last value) as needed */
}
