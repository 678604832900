.burger-menu {
  position: relative;
}

.burger-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.burger-line {
  width: 100%;
  height: 3px;
  margin: 5px 0;
  transition: all 0.3s ease-in-out;
}

.openI {
  transform: rotate(-45deg) translateY(6px);
}

.openII {
display: none;
}

.openIII {
  transform: rotate(45deg)translateY(-6px);
}

.menu-list {
  position: absolute;
  top: 50px;
  right: 20px;
  border: 1px solid #333;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
@media screen and (max-width: 365px) {
  .menu-list{
    right: -15px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
